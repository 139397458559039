import Vue from 'vue';
import '@mdi/font/css/materialdesignicons.css'
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);


const opts = {
    theme: {
          themes: {
              light: {
                primary: '#1cc695',
                secondary: '#f2552c',
                dark: '#000008',
                veryPoorStatus: "#ffd8d8",
                poorStatus: "#ffd8d8",
                averageStatus: "#fff7d8",
                goodStatus: "#fff7d8",
                excellentStatus: "#d8ffef",
                veryPoorStatusText: "#a51b1b",
                poorStatusText: '#a59c1b',
                averageStatusText: '#a59c1b',
                veryGoodStatusText: "#a59c1b",
                amazingStatusText: "#2a9b6d",
              },
          },
      },
  }

export default new Vuetify(opts);
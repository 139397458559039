import Vue from "vue";
import api from "../../api/api";

// State object
const state = {
  data: {
    count: 0,
    links: {
      next: null,
      previous: null,
    },
    results: [],
  },
  pageNumber: 1,
  loading: false,
};

// Getter functions
const getters = {};

// Actions
const actions = {
  getUsersReports({commit}, payload) {
    commit('SET_LOADING', true);

    api.get(`/admin_reports/user/${payload.userId}`
    )
    .then((res) => {
      commit('SET_USER_GENERATED_REPORTS', {user: payload.userId, generated_reports: res.data.count})

      commit('SET_LOADING', false);
    })
    .catch((error) => {
      this._vm.$eventHub.$emit(
        "error-notification",
        error.response.data.error
      );

      commit("SET_LOADING", false);
    });
  },
  getUsers({ dispatch, commit, state }, payload) {
    commit("SET_LOADING", true);

    api
      .get("/auth/users_filter", {
        params: {
          page: state.pageNumber,
          status: payload.status,
          role: payload.role,
          "sort by remaining reports": payload.sort_by_remaining_reports,
        },
      })
      .then((res) => {
        commit("SET_DATA", res.data);

        state.data.results.forEach(element => {
          dispatch('getUsersReports', {
            userId: element.id,
          });
        });

        commit("SET_LOADING", false);
      })
      .catch((error) => {
        this._vm.$eventHub.$emit(
          "error-notification",
          error.response.data.error
        );

        commit("SET_LOADING", false);
      });
  },
  getUsersSearch({ dispatch, commit }, payload) {
    commit("SET_LOADING", true);

    api
      .get("/auth/users_search", {
        params: {
          page: state.pageNumber,
          email: payload.email,
          name: payload.name,
          user_plan: payload.user_plan,
        },
      })
      .then((res) => {
        commit("SET_DATA", res.data);

        state.data.results.forEach(element => {
          dispatch('getUsersReports', {
            userId: element.id,
          });
        });

        commit("SET_LOADING", false);
      })
      .catch((error) => {
        this._vm.$eventHub.$emit(
          "error-notification",
          error.response.data.detail
        );

        commit("SET_LOADING", false);
      });
  },
};

// Mutations
const mutations = {
  SET_DATA: (state, data) => {
    data.results.map((item) => {
      item.is_active = item.is_active ? "Yes" : "No";
      item.is_business = item.is_business ? "Yes" : "No";
      item.is_affiliate = item.is_affiliate ? "Yes" : "No";
      return item;
    });

    state.data = data;
  },
  UPDATE_PAGE_NUMBER: (state, pageNumber) => (state.pageNumber = pageNumber),
  SET_LOADING: (state, loading) => (state.loading = loading),
  SET_USER_GENERATED_REPORTS: (state, user_reports) => {
    state.data.results.forEach(user => {
      if(user.id == user_reports.user) {
        Vue.set(user, 'generated_reports', user_reports.generated_reports)
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

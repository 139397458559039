<template>
  <v-container class="text-center mt-3">
    <p>Welcome to the administration of the 'Robinize' application. This administration allows you to more easily manage the content on the application. </p>
  </v-container>
</template>

<script>
  export default {
    name: 'Dashboard'
  }
</script>

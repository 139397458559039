import api from '../../api/api'

// State object
const state = {
    data: {
        count: 0,
        links:{
            next: null,
            previous: null
        },
        results: []
    },
    pageNumber: 1,
    loading: false
}

// Getter functions
const getters = {

}

// Actions 
const actions = {
    getReports({ commit, state }){
        commit('SET_LOADING', true)

        api.get('/admin_reports/', { 
            params: {
                page: state.pageNumber
            }
        })
        .then((res) => {
            commit('SET_DATA', res.data)

            commit('SET_LOADING', false)
        })
        .catch((error) => {
            this._vm.$eventHub.$emit('error-notification', error.response.data.error);

            commit('SET_LOADING', false)
        })
    },
}

// Mutations
const mutations = {
    SET_DATA: (state, data) => state.data = data,
    UPDATE_PAGE_NUMBER: (state, pageNumber) => state.pageNumber = pageNumber,
    SET_LOADING: (state, loading) => state.loading = loading
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
} 
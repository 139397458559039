import api from '../../api/api';
import { currentDate, cleanVuexData } from '../../helpers/index'; 

// State object
const state = {
    pageNumber: 1,
    data: {
        count: 0,
        links:{
            next: null,
            previous: null
        },
        results: []
    },
    canceledStartDate: currentDate,
    canceledEndDate: currentDate,
    creationStartDate: currentDate,
    creationEndDate: currentDate,
    currentPeriodStartDate: currentDate,
    currentPeriodEndDate: currentDate,
    currentPeriodStartStartDate: currentDate,
    currentPeriodStartEndDate: currentDate,
    endedAtStartDate: currentDate,
    endedAtEndDate: currentDate,
    status: '',
    customerId: '',
    subscriptionId: '',
    currentItem: '',
    loading: false,
}

// Getter functions
const getters = {

}

// Actions 
const actions = {
    refreshData({ dispatch, state }, payload){
        switch(state.currentItem) {
            case 'All':
                dispatch('getSubscriptions')
                break;
            case 'Cancellation date':
                dispatch('getSubscriptionsByCanceledAt', payload)
                break;
            case 'Creation date':
                dispatch('getSubscriptionsByCreationDate', payload)
                break;
            case 'Current period start':
                dispatch('getSubscriptionsByCurrentPeriodStart', payload)
                break;
            case 'Current period end':
                dispatch('getSubscriptionsByCurrentPeriodEnd', payload)
                break;
            case 'Customer id':
                dispatch('getSubscriptionsByCustomerId', payload)
                break;
            case 'Ended at':
                dispatch('getSubscriptionsByEndedAt', payload)
                break;   
            case 'Status':
                dispatch('getSubscriptionsByStatus', payload)
                break; 
            default:
                dispatch('getSubscriptions', payload)
        } 
    },
    getSubscriptions({ commit, state }){
        commit('SET_LOADING', true)

        api.get('/payments/admin_subscriptions/', { 
            params: {
                page: state.pageNumber,
            }
        })
        .then((res) => {
            commit('SET_DATA', res.data)

            commit('SET_LOADING', false)
        })
        .catch((error) => {
            //console.log(err);
            this._vm.$eventHub.$emit('error-notification', error.response.data.error);

            commit('SET_LOADING', false)
        })
    },
    getSubscriptionsByCanceledAt({ commit, state }){
        commit('SET_LOADING', true)
        api.get('/payments/admin_subscriptions/get_by_canceled_at/', { 
            params: {
                page: state.pageNumber,
                canceled_start_date: state.canceledStartDate,
                canceled_end_date: state.canceledEndDate 
            }
        })
        .then((res) => {
            commit('SET_DATA', res.data)

            commit('SET_LOADING', false)
        })
        .catch((error) => {
            //console.log(err);
            this._vm.$eventHub.$emit('error-notification', error.response.data.error);

            commit('SET_LOADING', false)
        })
    },
    getSubscriptionsByCreationDate({ commit, state }){
        commit('SET_LOADING', true)
        api.get('/payments/admin_subscriptions/get_by_creation_date/', { 
            params: {
                page: state.pageNumber,
                created_start_date: state.creationStartDate,
                created_end_date: state.creationEndDate 
            }
        })
        .then((res) => {
            commit('SET_DATA', res.data)

            commit('SET_LOADING', false)
        })
        .catch((error) => {
            //console.log(err);
            this._vm.$eventHub.$emit('error-notification', error.response.data.error);

            commit('SET_LOADING', false)
        })
    },
    getSubscriptionsByCurrentPeriodEnd({ commit, state }){
        commit('SET_LOADING', true)
        api.get('/payments/admin_subscriptions/get_by_current_period_end/', { 
            params: {
                page: state.pageNumber,
                start_date: state.currentPeriodStartDate,
                end_date: state.currentPeriodEndDate 
            }
        })
        .then((res) => {
            commit('SET_DATA', res.data)

            commit('SET_LOADING', false)
        })
        .catch((error) => {
            //console.log(err);
            this._vm.$eventHub.$emit('error-notification', error.response.data.error);

            commit('SET_LOADING', false)
        })
    },
    getSubscriptionsByCurrentPeriodStart({ commit, state }){
        commit('SET_LOADING', true)
        api.get('/payments/admin_subscriptions/get_by_current_period_start/', { 
            params: {
                page: state.pageNumber,
                start_date: state.currentPeriodStartStartDate,
                end_date: state.currentPeriodStartEndDate 
            }
        })
        .then((res) => {
            commit('SET_DATA', res.data)

            commit('SET_LOADING', false)
        })
        .catch((error) => {
            //console.log(err);
            this._vm.$eventHub.$emit('error-notification', error.response.data.error);

            commit('SET_LOADING', false)
        })
    },
    getSubscriptionsByCustomerId({ commit, state }){
        commit('SET_LOADING', true)
        api.get('/payments/admin_subscriptions/get_by_customer_id/', { 
            params: {
                page: state.pageNumber,
                customer_id: state.customerId
            }
        })
        .then((res) => {
            commit('SET_DATA', res.data)

            commit('SET_LOADING', false)
        })
        .catch((error) => {
            //console.log(err);
            this._vm.$eventHub.$emit('error-notification', error.response.data.error);

            commit('SET_LOADING', false)
        })
    },
    getSubscriptionsByEndedAt({ commit, state }){
        commit('SET_LOADING', true)
        api.get('/payments/admin_subscriptions/get_by_ended_at/', { 
            params: {
                page: state.pageNumber,
                start_date: state.endedAtStartDate,
                end_date: state.endedAtEndDate,
            }
        })
        .then((res) => {
            commit('SET_DATA', res.data)

            commit('SET_LOADING', false)
        })
        .catch((error) => {
            //console.log(err);
            this._vm.$eventHub.$emit('error-notification', error.response.data.error);

            commit('SET_LOADING', false)
        })
    },
    getSubscriptionsByStatus({ commit, state }){
        commit('SET_LOADING', true)
        api.get('/payments/admin_subscriptions/get_by_status/', { 
            params: {
                page: state.pageNumber,
                status: state.status
            }
        })
        .then((res) => {
            commit('SET_DATA', res.data)

            commit('SET_LOADING', false)
        })
        .catch((error) => {
            //console.log(err);
            this._vm.$eventHub.$emit('error-notification', error.response.data.error);

            commit('SET_LOADING', false)
        })
    },
}

// Mutations
const mutations = {
    SET_DATA: (state, data) => state.data = data,
    UPDATE_CANCELED_START_DATE: (state, canceledStartDate) => state.canceledStartDate = canceledStartDate,
    UPDATE_CANCELED_END_DATE: (state, canceledEndDate) => state.canceledEndDate = canceledEndDate,
    UPDATE_CREATION_START_DATE: (state, creationStartDate) => state.creationStartDate = creationStartDate,
    UPDATE_CREATION_END_DATE: (state, creationEndDate) => state.creationEndDate = creationEndDate,
    UPDATE_CURRENT_PERIOD_START_DATE: (state, currentPeriodStartDate) => state.currentPeriodStartDate = currentPeriodStartDate,
    UPDATE_CURRENT_PERIOD_END_DATE: (state, currentPeriodEndDate) => state.currentPeriodEndDate = currentPeriodEndDate,
    UPDATE_CURRENT_PERIOD_START_START_DATE: (state, currentPeriodStartStartDate) => state.currentPeriodStartStartDate = currentPeriodStartStartDate,
    UPDATE_CURRENT_PERIOD_START_END_DATE: (state, currentPeriodStartEndDate) => state.currentPeriodStartEndDate = currentPeriodStartEndDate,
    UPDATE_ENDED_AT_START_DATE: (state, endedAtStartDate) => state.endedAtStartDate = endedAtStartDate,
    UPDATE_ENDED_AT_END_DATE: (state, endedAtEndDate) => state.endedAtEndDate = endedAtEndDate,
    UPDATE_CUSTOMER_ID: (state, customerId) => state.customerId = customerId,
    UPDATE_STATUS: (state, status) => state.status = status,
    UPDATE_CURRENT_ITEM: (state, currentItem) => state.currentItem = currentItem,
    UPDATE_PAGE_NUMBER: (state, pageNumber) => state.pageNumber = pageNumber,
    SET_LOADING: (state, loading) => state.loading = loading,
    CLEAN_DATA: (state) => { 
        const attributes =  {
            dateTime: ['canceledStartDate', 'canceledEndDate', 'creationStartDate', 'creationEndDate', 'currentPeriodStartDate', 'currentPeriodEndDate', 'currentPeriodStartStartDate', 'currentPeriodStartEndDate', 'endedStartDate', 'endedEndDate'],
            textInput: ['customerId', 'subscriptionId'],
            select: [{ name: 'status', default: 'active' }],
            currentItem: state.currentItem
        };

        cleanVuexData(state, attributes);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
} 
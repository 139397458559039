import api from '../../api/api';
import router from '../../router/index';

// State object
const state = {
    isLoggedIn: false,
    user: {
        id: '',
        name: '',
        email: '',
        avatar: '',
        is_active: false

    },
    errors: false
}

// Getter functions
const getters = {

}

// Actions 
const actions = {
    login({ commit, dispatch }, payload){
        api.post('/auth/adminlogin', payload)
        .then((response) => {
            //Storing user data and user credentials
            commit('UPDATE_USER', response.data.user)
            commit('UPDATE_CREDENTIALS', { 
                token: response.data.token, 
                refresh: response.data.refresh
             })

            localStorage.setItem('user', JSON.stringify({
                access: response.data.access,
                refresh: response.data.refresh
            }))

            /*
                Without this auth/users/me route will be 
                called with old access token and return 403
            */
            dispatch('updateAuthHeaders', null)

            //Navigating to home screen of admin panel
            router.push({ name: 'Dashboard' })
        })
        .catch((error) => {
            commit('UPDATE_ERRORS', error)
            this._vm.$eventHub.$emit('error-notification', error.response.data.detail);
        })
    },
    logout(){
        localStorage.removeItem('user')

        router.push({ name: 'Login' })
    },
    checkLoginStatus({ commit }){
        const user = localStorage.getItem('user')
        const status = user !== null ? true : false

        //commit('UPDATE_USER', user)
        commit('UPDATE_STATUS', status)
    },
    updateAuthHeaders(){
        let user = JSON.parse(localStorage.getItem('user'))
        let access = user && user.access

        api.defaults.headers['Authorization'] = `Bearer ${ access }`
    },
    getUser({ commit }){
        api.get('/auth/users/me')
        .then((response) => {
            commit('UPDATE_USER', response.data)
        })
        .catch((error) => {
            console.log(error)
        })
    },
    sessionDialog(){
        this._vm.$eventHub.$emit('session-dialog', true);
    }
}

// Mutations
const mutations = {
    UPDATE_USER: (state, user) => state.user = user,
    UPDATE_CREDENTIALS: (state, credentials) => state.credentials = credentials,
    UPDATE_ERRORS: (state, errors) => state.errors = errors,
    UPDATE_STATUS: (state, status) => state.isLoggedIn = status,
    SAVE_ACCESS_TOKEN: (state, accessToken) => {
        //Parsing old access token
        const user = JSON.parse(localStorage.getItem('user'))

        //Setting up new/refreshed access token to vuex and local storage
        user.access = accessToken
        state.user = user;
        localStorage.setItem('user', JSON.stringify({
            access: user.access,
            refresh: user.refresh
        }))

        //Setting up request headers with new access token
        api.defaults.headers['Authorization'] = `Bearer ${ accessToken }`
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
} 
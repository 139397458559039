import api from '../../api/api'


const state = {
    user: {
        name: '',
        avatar: '',
        is_business: '',
        is_affiliate: '',
        is_active: '',
        remaining_reports: 0,
        user_plan: {
            description: null
        }
    },
    customer:{
        id: null
    },
    customerSubscriptions: {
        count: 0,
        links:{
            next: null,
            previous: null
        },
        results: []
    },
    subscriptionsPageNumber: 1,
    customerInvoices:{
        count: 0,
        links:{
            next: null,
            previous: null
        },
        results: []
    },
    invoicesPageNumber: 1,
    userArticles:{
        count: 0,
        links:{
            next: null,
            previous: null
        },
        results: []
    },
    articlesPageNumber: 1,
    userReports: {
        count: 0,
        links:{
            next: null,
            previous: null
        },
        results: []
    },
    reportsPageNumber: 1,
    users: [],
    loading: false,
    pageCount: 0
}

const getters = {

}

const actions = {
    getAllUsers({ commit }) {
        commit('SET_LOADING', true)
        api.get("/auth/users/", {
        })
        .then((res) => {
            commit('SET_USERS', res.data);
            commit('SET_LOADING', false);
            commit('SET_PAGECOUNT', res.data.count)
        })
        .catch((err) => {
            console.log(err);
        })
    },
    getUsersPagination({ commit }, page) {
        commit('SET_LOADING', true);
        api.get('auth/users/', {
            params: {
                page: page
            }
        })
        .then((res) => {
            commit('SET_USERS', res.data);
            commit('SET_LOADING', false);
            commit('SET_PAGECOUNT', res.data.count)
        })
        .catch((err) => {
            console.log(err);
        })
    },
    //User view
    getUser({ commit }, id){
        api.get(`/auth/users/${id}`)
        .then((res) => {
            commit('SET_USER', res.data);
        })
        .catch(() => {
            //console.log(err);
            this._vm.$eventHub.$emit('error-notification', 'Error while loading!');
        })
    },
    //Change reports array size
    changeRowsPerPage({ commit }, payload){
        const newItems = { ...payload.items }
        newItems.results = newItems.results.slice(0, payload.rows);
        
        //console.log(newReports);

        commit(payload.commitName, newItems);
    },
    //User view -> user reports
    getUserReports({ dispatch, commit, state }, payload){
        commit('SET_LOADING', true)

        api.get(`/admin_reports/user/${payload.userId}`, { 
            params: {
                page: state.reportsPageNumber
            }
        })
        .then((res) => {
            //commit('SET_USER_REPORTS', res.data);
            //console.log(res.data)
            dispatch('changeRowsPerPage', {
                items: res.data,
                rows: res.data.count,
                commitName: 'SET_USER_REPORTS'
            });

            commit('SET_LOADING', false)
        })
        .catch((error) => {
            //console.log(err);
            this._vm.$eventHub.$emit('error-notification', error.response.data.error);

            commit('SET_LOADING', false)
        })
    },
     //User view -> user articles
     getUserArticles({ dispatch, commit, state }, payload){
         commit('SET_LOADING', true)

        api.get(`/admin_articles/user/${payload.userId}`, { 
            params: {
                page: state.articlesPageNumber,
            }
        })
        .then((res) => {
            dispatch('changeRowsPerPage', {
                items: res.data,
                rows: res.data.count,
                commitName: 'SET_USER_ARTICLES'
            });

            commit('SET_LOADING', false)
        })
        .catch((error) => {
            //console.log(err);
            this._vm.$eventHub.$emit('error-notification', error.response.data.error);
            commit('SET_LOADING', false)
        })
    },
    //User view -> getting stripe customer from user id
    async getCustomer({ commit }, userId){
        await api.get('/payments/admin_customer/get_customer_by_subscriber_id/', {
            params:{
                subscriber_id: userId
            }
        })
        .then((res) => {
            //console.log("CUSTOMER: ", res.data)
            commit('SET_CUSTOMER', res.data);
        })
        .catch((error) => {
            //console.log(err);
            this._vm.$eventHub.$emit('error-notification', error.response.data.error);
        })
    },
    //User view -> get customer invoices
    async getCustomerInvoices({ dispatch, commit, state }, payload){
        commit('SET_LOADING', true)

        await dispatch('getCustomer', payload.userId);

        api.get('/payments/admin_invoice/get_by_customer_id/', {
            params:{
                customer_id: state.customer.id
            }
        })
        .then((res) => {
                commit('SET_CUSTOMER_INVOICES', res.data)
                commit('SET_LOADING', false)
                //console.log("CustomerInvoice LOG: ", res.data)
        })
        .catch((error) => {
            //console.log(err);
            this._vm.$eventHub.$emit('error-notification', error.response.data.error);
            commit('SET_LOADING', false)
        })
    },
    //User view -> get customer invoices
    async getCustomerSubscriptions({ dispatch, commit, state }, payload){
        commit('SET_LOADING', true)

        await dispatch('getCustomer', payload.userId);

        //console.log(state.customer)

        api.get('/payments/admin_subscriptions/get_by_customer_id/', {
            params:{
                customer_id: state.customer.id
            }
        })
        .then((res) => {
                commit('SET_CUSTOMER_SUBSCRIPTIONS', res.data)
                commit('SET_LOADING', false)
                //console.log("CustomerInvoice LOG: ", res.data)
        })
        .catch((error) => {
            //console.log(err);
            this._vm.$eventHub.$emit('error-notification', error.response.data.error);
            commit('SET_LOADING', false)
        })
    },
    //User view -> save user data
    saveUser({ state, commit }, id){
        commit('SET_LOADING', true)

        const userData = (({ name, is_business, is_affiliate, is_active, remaining_reports }) => ({ name, is_business, is_affiliate, is_active, remaining_reports }))(state.user);

        api.patch(`/auth/users/${id}`, userData)
        .then((res) => {
            commit('SET_USER', res.data);

            this._vm.$eventHub.$emit('success-notification', 'User data successfully saved!');

            commit('SET_LOADING', false)
        })
        .catch((error) => {
            this._vm.$eventHub.$emit('error-notification', error.response.data.detail);

            commit('SET_LOADING', false)
        })
    },
    //filtering users
    async filterUsers ({ commit }, filters) {
        commit('SET_LOADING', true);

        await api.get(`/auth/users_filter`, {
            params: {
                page: filters.page,
                status: filters.status,
                role: filters.role,
                'sort by remaining reports': filters.report,
            }
        })
        .then((res) => {
            commit('SET_USERS', res.data);
            commit('SET_PAGECOUNT', res.data.count);
            commit('SET_LOADING', false);

        })
        .catch((error) => {
            this._vm.$eventHub.$emit('error-notification', error.response.data.detail);

            commit('SET_LOADING', false);
        })
    }
}

const mutations = {
    SET_USER: (state, user) => state.user = user,
    SET_CUSTOMER: (state, customer) => state.customer = customer,
    SET_CUSTOMER_INVOICES: (state, customerInvoices) => state.customerInvoices = customerInvoices,
    UPDATE_INVOICES_PAGE_NUMBER: (state, pageNumber) => state.invoicesPageNumber = pageNumber,
    SET_CUSTOMER_SUBSCRIPTIONS: (state, customerSubscriptions) => state.customerSubscriptions = customerSubscriptions,
    UPDATE_SUBSCRIPTIONS_PAGE_NUMBER: (state, pageNumber) => state.subscriptionsPageNumber = pageNumber,
    SET_USER_REPORTS: (state, userReports) => state.userReports = userReports,
    UPDATE_REPORTS_PAGE_NUMBER: (state, pageNumber) => state.reportsPageNumber = pageNumber,
    SET_USER_ARTICLES: (state, userArticles) => state.userArticles = userArticles,
    UPDATE_ARTICLES_PAGE_NUMBER: (state, pageNumber) => state.articlesPageNumber = pageNumber,
    SET_USERS: (state, users) => state.users = users,
    SET_LOADING: (state, loading) => state.loading = loading,
    SET_PAGECOUNT: (state, count) => state.pageCount = Math.ceil(count / 10)
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
<template>
  <v-app>

    <EventHub />

    <Layout v-if="isLoggedIn"/>

    <router-view v-else></router-view>
    
  </v-app>
</template>

<script>
import EventHub from './components/event_hub/EventHub.vue'
import Layout from './components/layout/Layout.vue'
import { mapState, mapActions } from 'vuex';

export default {
  name: 'App',
  components:{
    EventHub,
    Layout
  },
  watch:{
    $route:{
      handler(){
        this.checkLoginStatus()
        
        if(this.isLoggedIn){

          this.updateAuthHeaders()

        }
      },
      immediate: true
    }
  },
  data: () => ({
    //
  }),
  methods:{
    ...mapActions('auth', ['checkLoginStatus', 'updateAuthHeaders'])
  },
  computed: {
    ...mapState('auth', ['isLoggedIn'])
  },
};
</script>

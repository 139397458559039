import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import user from './modules/user'
import reports from './modules/reports'
import articles from './modules/articles'
import invoices from './modules/invoices'
import subscriptions from './modules/subscriptions'
import plans from './modules/plans'
import users from './modules/users'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    users,
    user,
    reports,
    articles,
    invoices,
    subscriptions,
    plans
  }
})

<template>
    <div>
        <v-navigation-drawer
        v-model="drawer"
        app
        >

        <!--Route links-->
            <v-list
            dense
            rounded
            >
            <div v-for="route in routes" :key="route.title">
            <v-list-item
                v-if="!route.hasOwnProperty('subRoutes')"
                link
                :to="route.link"
            >
                
                <v-list-item-icon>
                <v-icon>{{ route.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                <v-list-item-title>{{ route.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-group
                v-else
                :value="false"
                :prepend-icon="route.icon"
            >
                <template v-slot:activator>
                    <v-list-item-title>{{ route.title }}</v-list-item-title>
                </template>

                <v-list-item
                    v-for="(subRoute, i) in route.subRoutes"
                    :key="i"
                    link
                    :to="subRoute.link"
                >
                    <v-list-item-icon>
                        <v-icon v-text="subRoute.icon"></v-icon>
                    </v-list-item-icon>

                    <v-list-item-title v-text="subRoute.title"></v-list-item-title>
                </v-list-item>
            </v-list-group>
            </div>

            </v-list>
        </v-navigation-drawer>

        <!--AppBar-->
        <v-app-bar app dark color="primary">
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

            <v-toolbar-title>Robinize Admin</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn text :to="'users/' + user.id"> {{ user.name }} </v-btn>

            <v-btn
              icon
              color="white"
              @click="handleLogout"
            >
              <v-icon>mdi-logout</v-icon>
            </v-btn>
        </v-app-bar>

        <v-main>
            <router-view></router-view>
        </v-main>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    data(){
        return{
            drawer: null,
            routes: [
                { title: 'Dashboard', icon: 'mdi-view-dashboard', link: "/" },
                { title: 'Users', icon: 'mdi-account', link: "/users" },
                { title: 'Reports', icon: 'mdi-file-chart', link: "/reports" },
                { title: 'Articles', icon: 'mdi-file-document', link: "/articles" },
                { title: 'Payments', icon: 'mdi-currency-usd', link: "/payments", subRoutes: [
                    { title: 'Invoices', icon: 'mdi-receipt', link: "/invoices" },
                    { title: 'Plans', icon: 'mdi-pencil-box-multiple', link: "/plans" },
                    { title: 'Subscriptions', icon: 'mdi-playlist-check', link: "/subscriptions" }
                ] },
            ],
        }
    },
    computed: {
        ...mapState('auth', ['user'])
    },
    methods:{
        ...mapActions('auth', ['logout', 'getUser']),
        handleLogout(){
            this.logout()
        }
    },
    created(){
        //Get currently logged user
        this.getUser()
    }
}
</script>

<style scoped>
    .hero-text{
        font-size: 17px;
        font-weight: 500;
        letter-spacing: 1.2px;
        color: white;
        text-align: center;
    }
    .border-radius-default{
        border-radius: 0px !important;
    }
</style>
import axiosInstance from '../api/api'

const setup = (store) => {
  //Getting user data from local storage
  let user = JSON.parse(localStorage.getItem('user'));

  let access = user && user.access;
  let refresh = user && user.refresh;

  //Getting access for auth vuex module
  const authModule = store._modulesNamespaceMap["auth/"].context;

  axiosInstance.interceptors.request.use(
    (config) => {
      const token = access;
      if (token) {
        //Setup Auth headers for every API call
        //config.headers["Authorization"] = 'Bearer ' + token; 

        authModule.dispatch('updateAuthHeaders');
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (originalConfig.url !== "/auth/adminlogin" && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;

          try {
            const refreshToken = await axiosInstance.post("/auth/jwt/refresh", {
              refresh: refresh,
            });

            const accessToken = refreshToken.data.access;

            //Save new access token via vuex mutation
            const authModule = store._modulesNamespaceMap["auth/"].context;
            authModule.commit('SAVE_ACCESS_TOKEN', accessToken);

            return axiosInstance(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        //Show session expired modal dialog and logout user
        }else if(err.response.status === 403 && !originalConfig._retry){
          authModule.dispatch('sessionDialog');
        }
      }

      return Promise.reject(err);
    }
  );
};

export default setup;

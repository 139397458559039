import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Snotify from 'vue-snotify'; 
import 'vue-snotify/styles/material.css';
import setupInterceptors from './services/setupInterceptors';

Vue.config.productionTip = false

setupInterceptors(store);

//Global event hub
Vue.prototype.$eventHub = new Vue();

//Notifications UI
Vue.use(Snotify)

//Making vuex available in Cypress for e2e tests
if (window.Cypress) {
  window.__store__ = store
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

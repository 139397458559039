<template>
  <div>
      <!--Notifications-->
      <vue-snotify></vue-snotify>

      <!--Session dialog-->
      <v-dialog
        v-model="sessionDialog"
        max-width="600"
        >
            <template>
                <v-card>
                <v-toolbar
                    color="primary"
                    dark
                >Session expired!</v-toolbar>
                <v-card-text>
                    <div class="text-h5 pa-12">Please login to extend the session.</div>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn
                    color="primary"
                    @click="handleLogout"
                    >OK</v-btn>
                </v-card-actions>
                </v-card>
            </template>
        </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    data(){
        return{
            loader: null,
            sessionDialog: false
        }
    },
    methods:{
        ...mapActions('auth', ['refresh', 'logout']),
        successNotification(message){
            this.$snotify.success(message, {
                timeout: 2000,
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                position: 'rightTop'
            });
                
        },
        errorNotification(message){
            this.$snotify.error(message, {
                timeout: 2000,
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                position: 'rightTop'
            });
                
        },   
        invokeSessionDialog(status){
            this.sessionDialog = status
        },
        handleLogout(){
            this.sessionDialog = false;

            this.logout()
        }
    },
    created(){
        this.$eventHub.$on('success-notification', (message) => this.successNotification(message)),
        this.$eventHub.$on('error-notification', (message) => this.errorNotification(message))
        this.$eventHub.$on('session-dialog', (status) => this.invokeSessionDialog(status))
    },
    beforeDestroy(){
        this.$eventHub.$off('success-notification'),
        this.$eventHub.$off('error-notification')
        this.$eventHub.$off('session-dialog')
    }
}
</script>

<style scoped>
</style>
import api from '../../api/api';
import { cleanVuexData } from '../../helpers/index';

// State object
const state = {
    pageNumber: 1,
    data: {
        count: 0,
        links:{
            next: null,
            previous: null
        },
        results: []
    },
    customerEmail: '',
    customerId: '',
    subscriptionId: '',
    currentItem: '',
    loading: false,
}

// Getter functions
const getters = {

}

// Actions 
const actions = {
    refreshData({ dispatch, state }, payload){
        switch(state.currentItem) {
            case 'All':
                dispatch('getInvoices')
                break;
            case 'Customer email':
                dispatch('getInvoicesByCustomerEmail', payload)
                break;
            case 'Customer id':
                dispatch('getInvoicesByCustomerId', payload)
                break;
            case 'Subscription id':
                dispatch('getInvoicesBySubscriptionId', payload)
                break;
            default:
                dispatch('getInvoices', payload)
        } 
    },
    getInvoices({ commit, state }){
        commit('SET_LOADING', true)

        api.get('/payments/admin_invoice/', { 
            params: {
                page: state.pageNumber,
            }
        })
        .then((res) => {
            commit('SET_DATA', res.data)

            commit('SET_LOADING', false)
        })
        .catch((error) => {
            //console.log(err);
            this._vm.$eventHub.$emit('error-notification', error.response.data.error);

            commit('SET_LOADING', false)
        })
    },
    getInvoicesByCustomerEmail({ commit, state }){
        api.get('/payments/admin_invoice/get_by_customer_email/', { 
            params: {
                page: state.pageNumber,
                customer_email: state.customerEmail 
            }
        })
        .then((res) => {
            commit('SET_DATA', res.data)

            commit('SET_LOADING', false)
        })
        .catch((error) => {
            //console.log(err);
            this._vm.$eventHub.$emit('error-notification', error.response.data.error);

            commit('SET_LOADING', false)
        })
    },
    getInvoicesByCustomerId({ commit, state }){
        api.get('/payments/admin_invoice/get_by_customer_id/', { 
            params: {
                page: state.pageNumber,
                customer_id: state.customerId 
            }
        })
        .then((res) => {
            commit('SET_DATA', res.data)

            commit('SET_LOADING', false)
        })
        .catch((error) => {
            //console.log(err);
            this._vm.$eventHub.$emit('error-notification', error.response.data.error);

            commit('SET_LOADING', false)
        })
    },
    getInvoicesBySubscriptionId({ commit, state }){
        api.get('/payments/admin_invoice/get_by_subscription_id/', { 
            params: {
                page: state.pageNumber,
                subscription_id: state.subscriptionId 
            }
        })
        .then((res) => {
            commit('SET_DATA', res.data)

            commit('SET_LOADING', false)
        })
        .catch((error) => {
            //console.log(err);
            this._vm.$eventHub.$emit('error-notification', error.response.data.error);

            commit('SET_LOADING', false)
        })
    },
}

// Mutations
const mutations = {
    SET_DATA: (state, data) => state.data = data,
    UPDATE_CUSTOMER_EMAIL: (state, customerEmail) => state.customerEmail = customerEmail,
    UPDATE_CUSTOMER_ID: (state, customerId) => state.customerId = customerId,
    UPDATE_SUBSCRIPTION_ID: (state, subscriptionId) => state.subscriptionId = subscriptionId,
    UPDATE_CURRENT_ITEM: (state, currentItem) => state.currentItem = currentItem,
    UPDATE_PAGE_NUMBER: (state, pageNumber) => state.pageNumber = pageNumber,
    SET_LOADING: (state, loading) => state.loading = loading,
    CLEAN_DATA: (state) => { 
        const attributes =  {
            dateTime: [],
            textInput: ['status', 'customerId', 'subscriptionId'],
            select: [],
            currentItem: state.currentItem
        };

        cleanVuexData(state, attributes);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
} 
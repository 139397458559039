import api from '../../api/api'

// State object
const state = {
    data: {
        results: []
    },
    loading: false
}

// Getter functions
const getters = {

}

// Actions 
const actions = {
    getPlans({ commit, state }){
        commit('SET_LOADING', true)

        api.get('payments/subscriptions/', { 
            params: {
                page: state.pageNumber
            }
        })
        .then((res) => {
            commit('SET_DATA', res.data)

            commit('SET_LOADING', false)
        })
        .catch((error) => {
            //console.log(err);
            this._vm.$eventHub.$emit('error-notification', error.response.data.error);

            commit('SET_LOADING', false)
        })
    },
}

// Mutations
const mutations = {
    SET_DATA: (state, data) => {
        data.map((item) => {
            item.status = item.status ? "Yes" : "No" 
            item.premium_support = item.premium_support ? "Yes" : "No"
            item.is_enterprise = item.is_enterprise ? "Yes" : "No"
        })
        state.data.results = data
    },
    SET_LOADING: (state, loading) => state.loading = loading,
    UPDATE_PAGE_NUMBER: (state, pageNumber) => state.pageNumber = pageNumber
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
} 
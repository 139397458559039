<template>
    <v-container class="fill-height d-flex justify-center bg-color" fluid>
        <v-card class="d-flex flex-column jusitfy-space-evenly pa-5" elevation="10" max-width="450px" style="border-radius: 15px;">

            <!--Form section-->
            <div class="left-col">

                <!--Image section-->
                <v-container class="mb-5 text-center" fluid>
                    <img src="/robinize-logo.webp" alt="robinize" style="width: 80%;">
                </v-container>

                <v-form
                    ref="form"
                    v-model="valid"
                >
                    <label for="email">E-mail</label>
                    <v-text-field
                        v-model="form.email"
                        :rules="emailRules"
                        outlined
                        append-icon="mdi-email"
                        placeholder="Enter your e-mail address"
                        class="mt-2"
                        @keyup.enter="valid && handleLogin()"
                    ></v-text-field>

                    <label for="email">Password</label>
                    <v-text-field
                        type="password"
                        v-model="form.password"
                        :rules="passwordRules"
                        class="mt-2"
                        outlined
                        append-icon="mdi-key"
                        placeholder="Enter your password"
                        @keyup.enter="valid && handleLogin()"
                    ></v-text-field>
                </v-form>

                <v-container fluid class="mt-3 mb-3">
                    <v-btn color="secondary" @click="handleLogin" :disabled="!valid" block rounded>Login</v-btn>
                </v-container>
            
            </div>

        </v-card>
    </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
    data(){
        return{
            valid: false,
            form:{
                email: '',
                password: ''
            },
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be in valid form',
            ],
            passwordRules: [
                v => !!v || 'Password is required',
            ]
        }
    },
    computed: {
        ...mapState('auth', ['errors'])
    },
    methods:{
        ...mapActions('auth', ['login']),
        handleLogin(){
            this.login(this.form)
        }
    }
}
</script>

<style scoped>
    form-container{
        padding: 40px 10px 15px 40px;
        min-width: 350px;
    }

    @media only screen and (max-width: 960px){
        form-container{
            padding: 1.2em;
            min-width: 200px;
        }
    } 

    @media only screen and (max-width: 400px){
        form-container h1{
            font-size: 1.6em;
        }
    } 

    @media only screen and (max-width: 300px){
        form-container h1{
            font-size: 1.2em;
        }
    } 
</style>